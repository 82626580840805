var $class="se2--content-flexible-blocks",$name="ContentFlexibleBlocks",$path="app/components/ContentFlexibleBlocks/index.js",$this={$class,$name,$path,};import initializeSlider from 'app/components/utilities/initializeSlider';
import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import shell from 'app/modules/shell';

import 'app/partials/image';

import setYoutubeVideoThumbnail from 'app/utilities/setYoutubeVideoThumbnail';

import forEach from 'lodash/forEach';

import isDesktopBreakpoint from 'app/utilities/isDesktopBreakpoint';
import handleSlidesNavigation from 'app/components/utilities/handleSlidesNavigation';

import {
  contentEndClassName,
  nextButtonSelector,
  prevButtonSelector,
  slideSelector,
  slideDescriptionSelector,
  swiperContainerSelector,
  tipsHeaderSelector,
  tipsInfoSelector,
  tipsSelector,
} from './config';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount,
}) => {
  mount((element) => {
    const sliderEnable = () => {
      if (element.classList.contains('content-flexible-blocks-grid') && isDesktopBreakpoint()) {
        const onContainerKeyDown = (event) => {
          handleSlidesNavigation(
            event,
            null,
            null,
            null,
          );
        };

        addEventListener(element.querySelector(tipsSelector), 'keydown', onContainerKeyDown);
      }
      const slideElements = element.querySelectorAll(slideSelector);
      const tipsHeaderElement = element.querySelector(tipsHeaderSelector);
      const tipsInfoElement = element.querySelector(tipsInfoSelector);
      if (!tipsInfoElement) tipsHeaderElement.classList.add(contentEndClassName);

      if (slideElements.length) {
        const slider = initializeSlider(element, addEventListener, {
          containerSelector: tipsSelector,
          nextButtonSelector,
          prevButtonSelector,
          slideSelector,
          swiperContainerSelector,
          withInteractiveElements: true,
        });

        forEach(slideElements, (slideElement) => {
          slideElement.setAttribute(
            'role',
            slideElement.querySelectorAll('a').length === 1
            && !slideElement.querySelector(slideDescriptionSelector)
              ? 'link'
              : 'group',
          );
        });

        addEventListener(window, 'resize', slider.onResize);
      }
    };

    sliderEnable();
    addEventListener(window, 'resize', sliderEnable);
    const { videoThumbnail } = configs.css.classNames;
    setYoutubeVideoThumbnail(element, videoThumbnail);
    lazyHandleOpenModalButtons(addEventListener, element, [videoThumbnail]);
  });
});
